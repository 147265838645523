import React, { useState, useEffect, useRef} from 'react';

const StorageMoveForm = ({ onSuccess,setSelectedEmpaqueId }) => {
  const [storageOptions, setStorageOptions] = useState([]);
  const [barcode, setBarcode] = useState('');
  const [number, setNumber] = useState('');
  const [selectedEmpaque, setSelectedEmpaque] = useState(null);
  const [selectedStorageOption, setSelectedStorageOption] = useState('');
  const [sucursalActiva, setSucursal] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [mensaje, setMensaje] = useState({ type: '', lote: '', storageName: '' });
  const inputRef = useRef(null);

  useEffect(() => {
 
    try {
    const sucursalId = JSON.parse(localStorage.getItem("sucursal_activa")).id;
    if (sucursalId) {
        setSucursal(sucursalId);
    }
    } catch (error) {
    console.error("Error parsing localStorage data:", error);
    }
  }, []); // Solo se ejecuta una vez al montar el componente

  useEffect(() => {
    if (sucursalActiva) { // Verifica que sucursalActiva tenga un valor
      fetch(`${process.env.REACT_APP_API_URL}/storage-option/listarId?sucursal_id=${sucursalActiva}`)
        .then((response) => response.json())
        .then((data) => setStorageOptions(data))
        .catch((error) => console.error('Error al cargar storage options:', error));
    }
  }, [sucursalActiva]);
  
   // Buscar el empaque por código de barras
   const fetchEmpaqueByBarcode = (barcode) => {
    if (barcode) {
      fetch(`${process.env.REACT_APP_API_URL}/empaque/listarId/${barcode}`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setSelectedEmpaque(data);
            setSelectedEmpaqueId(data);
            onSuccess();
          } else {
            alert('Empaque no encontrado');
            setSelectedEmpaque(null);
          }
        })
        .catch((error) => console.error('Error al buscar empaque:', error));
    }
  };
  const handleBarcodeChange = (event) => {
    const barcodeInput = event.target.value;
    setBarcode(barcodeInput);
};
useEffect(() => {
    if (barcode.length == 9) {
        handleSubmit(); 
    }
}, [selectedEmpaque]);

// Función para manejar la búsqueda del empaque
const handleSearchEmpaque = () => {
    if (barcode.length == 9) {
        fetchEmpaqueByBarcode(barcode); 
    }
};
    useEffect(() => {
        handleSearchEmpaque(); 
    }, [barcode]);
    // Manejar el envío del formulario para mover el empaque
    const handleSubmit = (event) => {
        const codigoSinCorrelativo= barcode.slice(0,-3);
        // Verificar si ambos códigos están completos y son válidos
        if (selectedEmpaque.barcodeConfig.codigo_barras == codigoSinCorrelativo) {
            const data = {
                number:barcode.slice(-3),
                barcode:codigoSinCorrelativo,
                storageOption_id: selectedStorageOption,
            };

            fetch(`${process.env.REACT_APP_API_URL}/storage-history/move`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((response) => response.json())
                .then((data) => {
                    if(data.error){
                        inputRef.current.focus();
                        setMensaje(data.error)
                        setShowPopup(true);
                        console.log(data.error);
                        setTimeout(() => {
                            setShowPopup(false);
                        }, 3000);
                        setBarcode(''); 
                    }else{
                        inputRef.current.focus();
                        const barcodeAux=barcode;
                        setShowPopup(true);
                        onSuccess(); // Para refrescar la lista
                        console.log('Movimiento registrado:', data);
                        setMensaje('Movimiento registrado:', data)

                        setBarcode(''); // Limpiar el código de barras
                        fetchEmpaqueByBarcode(barcodeAux);  // Solo buscar cuando ambos valores sean válidos
                        setTimeout(() => {
                            setShowPopup(false);
                        }, 3000);

                    }
                   
                })
                .catch((error) => console.error('Error al mover el empaque:', error));
            }
    };

  return (
    <div className="card">
      <div className="card-header bg-primary text-white">
        <h4>Mover Empaque</h4>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="storageOption">Seleccionar Nueva Ubicación</label>
            <select
              className="form-control"
              id="storageOption"
              value={selectedStorageOption}
              onChange={(e) => setSelectedStorageOption(e.target.value)}
              required
            >
              <option value="">-- Selecciona una ubicación --</option>
              {storageOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="barcode">Código de Barras del Empaque</label>
            <input
              type="text"
              className="form-control"
              id="barcode"
              value={barcode}
              onChange={handleBarcodeChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Evita que el Enter envíe el formulario o cambie de página
                  handleSubmit();     // Llama a la función handleSubmit directamente si es necesario
                }
              }}
              ref={inputRef}
              placeholder="Escanea el código de barras"
              required
            />
          </div>
         
          {selectedEmpaque && (
            <div className="alert alert-info">
              <p>
                <strong>Ultimo Empaque Seleccionado:</strong> {selectedEmpaque.barcodeConfig.batch} - {selectedEmpaque.barcodeConfig.type} - {selectedEmpaque.number}
              </p>
            </div>
          )}
        </form>
        {showPopup && (
        <div className="popup">
          <p>{mensaje}</p>
        </div>
      )}
      </div>
      
     
    </div>
  );
};

export default StorageMoveForm;
