import React from 'react';
import StorageOptionForm from './opciones/OpcionesGuardadoForm';
import BarcodeConfigForm from './opciones/BarcodeConfigForm';

const OpcionesPage = () => {
  return (
    <div className="container">
      <h1>Configuraciónes</h1>
      <div className="row">
        <div className="col-md-12">
          <BarcodeConfigForm />
        </div>
        <div className="col-md-12">
          <StorageOptionForm />
        </div>
        
      </div>
    </div>
  );
};

export default OpcionesPage;
