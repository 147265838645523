import React, { useState, useEffect } from 'react';
import StorageMoveForm from './storageHistory/storageMoveForm';
import StorageHistoryList from './storageHistory/storageMoveList';

const StorageHistory = () => {
  const [selectedEmpaqueId, setSelectedEmpaqueId] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);

  const handleSuccess = () => {
    setRefreshFlag(!refreshFlag); // Forzar la actualización de la lista
  };
  return (
    <div className="container">
      <h1>Gestión de Movimientos de Empaques</h1>
      <div className="row">
        <div className="col-md-6">
          <StorageMoveForm onSuccess={handleSuccess} setSelectedEmpaqueId={setSelectedEmpaqueId}  />
        </div>
        <div className="col-md-6">
          <StorageHistoryList selectedEmpaqueId={selectedEmpaqueId} key={refreshFlag} />
        </div>
      </div>
    </div>
  );
};

export default StorageHistory;
