import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert, Form } from 'react-bootstrap';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Necesario para Chart.js v3+
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importar el plugin
import ExportToExcel from './Excel'; 
import ReactPaginate from 'react-paginate';
import '../css/Paginacion.css';
import axios from 'axios';

const Report = () => {
  const [loading, setLoading] = useState(true);

  // Estados para filtros
  const [formData, setFormData] = useState({
    type:"",
    batch:"",
    sucursal_id:JSON.parse(localStorage.getItem("sucursal_activa")).id, 
    cliente:"",
    alumbre:"",
    producto:"",
    clave:"",
    storageOption_id:"",
    estado:"",
    fecha: new Date().toISOString().split('T')[0],
    fechaHasta:""
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [empaquesPorHora, setEmpaquePorHora] = useState(1);

  const [kpis, setKpis] = useState('');
  const [opciones, setOpciones] = useState('');
  const [excel, setExcel] = useState('');
  const [storageOptions, setStorageOptions] = useState('');
  const [lotes, setLotes] = useState('');
  const [lotesPorTipo, setLotesPorTipo] = useState('');
  const [storageOptionsList, setStorageOptionsList] = useState('');
  const [typeList, setTypeList] = useState('');
  const [loteList, setLoteList] = useState('');
  const [clave, setClaveList] = useState('');
  const [cliente, setClienteList] = useState('');
  const [alumbre, setAlumbreList] = useState('');
  const [producto, setProductoList] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event.selected);
  };

  // Función para exportar a PDF
    const exportPDF = () => {
        const doc = new jsPDF();
    
        // Configurar márgenes, fuente, etc.
        doc.setFontSize(10);
        doc.text('Informe de Empaque', 10, 10);
        
        // Mapeamos la misma información del Excel para generar el PDF
        const info = excel.map((celda, index) => ({
            fechaHora: new Date(celda.horaGuardado).toLocaleString(),
            nombre: celda.nombre,
            lote: celda.barcodeConfig.batch,
            tipo: celda.barcodeConfig.type,
            numero: celda.number,
            clave: celda.barcodeConfig.clave,
            cliente: celda.barcodeConfig.cliente,
            producto: celda.barcodeConfig.producto,
            alumbre: celda.barcodeConfig.alumbre
        }));
    
        // Agregar encabezados
        const headers = ['Fecha y Hora', 'Nombre', 'Lote', 'Tipo', 'Número', 'Clave', 'Cliente', 'Producto', 'Alumbre'];
        const startY = 20;
        let y = startY;
    
        // Escribir encabezados en el PDF
        headers.forEach((header, index) => {
          doc.text(header, 10 + (index * 20), y);
        });
    
        y += 10; // Mover la posición para los datos
    
        // Agregar datos al PDF
        info.forEach((celda) => {
          doc.text(celda.fechaHora, 10, y);
          doc.text(celda.nombre, 30, y);
          doc.text(celda.lote, 50, y);
          doc.text(celda.tipo, 70, y);
          doc.text(celda.numero.toString(), 90, y);
          doc.text(celda.clave, 110, y);
          doc.text(celda.cliente, 130, y);
          doc.text(celda.producto, 150, y);
          doc.text(celda.alumbre, 170, y);
    
          y += 10;
          // Añadir nueva página si es necesario
          if (y > 280) {
            doc.addPage();
            y = startY;
          }
        });
    
        // Guardar el PDF
        doc.save('informe_empaque.pdf');
    };
    
  const rellenoVariables = (data, opcionesFiltros) => {
    setOpciones(data.opciones);
    setStorageOptions(data.storageOptions);
    setLotes(data.lotes);
    setKpis(data.kpis);
    setLotesPorTipo(data.lotesPorTipo);
   
    const empaquePorHoraData = data.excel.reduce((result, empaque) => {
        const fecha = new Date(empaque.horaGuardado);
        const hora = fecha.getHours(); // Obtener la hora (solo el número de la hora, sin minutos ni segundos)
      
            if (!result[hora]) {
            result[hora] = [];
            }
        
            result[hora].push(empaque);
            return result;
        }, {});
        const totalEmpaques = data.excel.length;
        const totalHoras = Object.keys(empaquePorHoraData).length;
        const promedioVelocidad = totalEmpaques / totalHoras;
        setEmpaquePorHora(promedioVelocidad);
            
    const options =opcionesFiltros.reduce((acc, opcion) => {
      acc.storageOptionsList.add(opcion.nombre);
      acc.typeList.add(opcion.barcodeConfig.type);
      acc.loteList.add(opcion.barcodeConfig.batch);
      acc.clave.add(opcion.barcodeConfig.clave);
      acc.cliente.add(opcion.barcodeConfig.cliente);
      acc.alumbre.add(opcion.barcodeConfig.alumbre);
      acc.producto.add(opcion.barcodeConfig.producto);
      return acc;
    }, {
      storageOptionsList: new Set(),
      sucursalList: new Set(),
      typeList: new Set(),
      loteList: new Set(),
      clave: new Set(),
      cliente: new Set(),
      alumbre: new Set(),
      producto: new Set(),
    });
    // Actualizando estados
    setTypeList([...options.typeList]);
    setStorageOptionsList([...options.storageOptionsList]);
    setLoteList([...options.loteList]);
    setClaveList([...options.clave]);
    setClienteList([...options.cliente]);
    setAlumbreList([...options.alumbre]);
    setProductoList([...options.producto]);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage,formData]);
  // Obtener datos desde la API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/empaque/informe`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          formData,
        },
      });
      const filtros = await axios.get(`${process.env.REACT_APP_API_URL}/empaque/filtros`);
      console.log(filtros);
      setExcel(response.data.excel);
      setTotalPages(response.data.totalPages);
      rellenoVariables(response.data,filtros.data.opcionesFiltros);
    } catch (error) {
      console.error('Error al obtener los datos', error);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!opciones) {
    return (
      <Alert variant="danger">
        Hubo un error al cargar el informe. Por favor, inténtalo de nuevo más tarde.
      </Alert>
    );
  }

  return (
    <div className="container my-4">
      <h2 className="mb-4">Informe de Empaque</h2>

      {/* Filtros */}
      <div className="row mb-4">
        <div className="col-md-6">
          <Form.Group >
            <Form.Label>Lugar de Almacenamiento</Form.Label>
            <Form.Control
              as="select"
              id="storageOption_id"
              name="storageOption_id"
              onChange={handleChange}
              value={formData.storageOption_id} 
            >
              <option value="">Todas</option>
              {storageOptionsList.map((option, index) => (
                <option key={index+"-StorageOption"} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              name="type"
              id="type"
              value={formData.type} 
              onChange={handleChange}
            >
              <option value="">Todos</option>
              {typeList.map((type, index) => (
                <option key={index+"-type"} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group >
            <Form.Label> Lote</Form.Label>
            <Form.Control
              as="select"
              name="batch"
              id="batch"
              value={formData.batch} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {loteList.map((lote, index) => (
                <option key={index+"-lote"} value={lote}>
                  {lote}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group >
            <Form.Label> Producto</Form.Label>
            <Form.Control
              as="select"
              name="producto"
              id="producto"
              value={formData.producto} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {producto.map((producto, index) => (
                <option key={producto+"-lote"} value={producto}>
                  {producto}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group>
            <Form.Label> Clave</Form.Label>
            <Form.Control
              as="select"
              id="clave"
              name="clave"
              value={formData.clave} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {clave.map((lote, index) => (
                <option key={index+"-lote"} value={lote}>
                  {lote}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Cliente</Form.Label>
            <Form.Control
              as="select"
              onChange={handleChange}
              name="cliente"
              value={formData.cliente} 

              id="cliente"
            >
              <option value="">Todos</option>
              {cliente.map((clave, index) => (
                <option key={index+"-clave"} value={clave}>
                  {clave}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group >
            <Form.Label>Alumbre</Form.Label>
            <Form.Control
              as="select"
              name="alumbre"
              id="alumbre"
              value={formData.alumbre} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {alumbre.map((alumbre, index) => (
                <option key={index+"-alumbre"} value={alumbre}>
                  {alumbre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group >
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              id="fecha"
              value={formData.fecha} 

              onChange={handleChange}
            />
          </Form.Group>
        </div>
        <div className="col-md-6">
            <Form.Group >
                <Form.Label>Estado de Almacenamiento</Form.Label>
                <Form.Control
                as="select"
                name="estado"
                id="estado"
                value={formData.producto} 

                onChange={handleChange}
                >
                <option value="">Todos</option>
                {/* Asegúrate de cambiar estos valores por los valores reales de estado */}
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
                </Form.Control>
            </Form.Group>
            </div>
      </div>

      {/* KPI Dashboard */}
      <div className="row mb-5">
        <div className="col-md-4">
          <div className="card text-white bg-primary mb-3">
            <div className="card-header">Total Empaques</div>
            <div className="card-body">
              <h5 className="card-title">{kpis.totalEmpaques}</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-white bg-success mb-3">
            <div className="card-header">Gramos Empacados</div>
            <div className="card-body">
              <h5 className="card-title"> {(kpis.totalEmpaques * 100 * 48).toLocaleString()}</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-white bg-warning mb-3">
            <div className="card-header">Empaque por hora</div>
            <div className="card-body">
              <h5 className="card-title">  {parseFloat(empaquesPorHora).toFixed(1)} </h5>
            </div>
          </div>
        </div>
      </div>
         {/* Tabla de Empaques */}
         <div className="row mb-5">
        <div className="col">
          <h5>Detalle de Empaques</h5>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Fecha y Hora</th>
                  <th>Nombre</th>
                  <th>Lote</th>
                  <th>Tipo</th>
                  <th>Número Identificador</th>
                  <th>Clave</th>
                  <th>Cliente</th>
                  <th>Producto</th>
                  <th>Alumbre</th>
                </tr>
              </thead>
              <tbody>
                {opciones.map((opcion, index) => (
                  <tr key={index+"-filter"}>
                    <td>{index+1}</td>
                    <td>{new Date(opcion.horaGuardado).toLocaleString()}</td>
                    <td>{opcion.nombre}</td>
                    <td>{opcion.barcodeConfig.batch}</td>
                    <td>{opcion.barcodeConfig.type}</td>
                    <td>{opcion.number}</td>
                    <td>{opcion.barcodeConfig.clave}</td>
                    <td>{opcion.barcodeConfig.cliente}</td>
                    <td>{opcion.barcodeConfig.producto}</td>
                    <td>{opcion.barcodeConfig.alumbre}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Siguiente'}
              breakLabel={'...'}
              pageCount={totalPages} // Número total de páginas
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick} // Llamada a la función al cambiar de página
              containerClassName={'pagination'}
              activeClassName={'active'} // Clase activa para la página seleccionada
            />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
            <h5>Cantidad por Lotes</h5>
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>Lote</th>
                <th>Typo</th>
                <th>Cantidad</th>
                </tr>
            </thead>
            <tbody>
                {lotesPorTipo.map((type, index) => (
                        type.lotes.map((lote, index2)=>(
                            <tr key={index+"-"+index2+"-lotesPorTipo"}>
                                <td>{lote.lote}</td>
                                <td>{type.type}</td>
                                <td>{lote.cantidad}</td>
                            </tr>
                        ))

                ))}
            </tbody>
            </Table>
        </div>
        </div>
      
      {/* Gráficos KPI */}
      <div className="row mb-5">
        <div className="col-md-6">
          <h5>Empaque por Almacenamiento</h5>
          <Bar
            data={{
              labels: storageOptions.map(s => s.nombre),
              datasets: [
                {
                  label: 'Cantidad de Empaques',
                  data: storageOptions.map(s => s.cantidadEmpaques),
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
              },
            }}
          />
        </div>
        <div className="col-md-6">
          <h5>Distribución de Alumbre</h5>
          <Pie
            data={{
              labels: lotes.map(l => l.nombre),
              datasets: [
                {
                  label: 'Cantidad por Alumbre',
                  data: lotes.map(l => l.cantidad),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                  ],
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
                datalabels: {
                  color: '#fff',
                  display: true,
                  formatter: (value, context) => {
                    const dataset = context.dataset;
                    const total = dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2) + '%';
                    return `${context.dataset.label}: ${value} (${percentage})`;
                  },
                },
              },
            }}
            plugins={[ChartDataLabels]} // Añadir el plugin aquí
          />
        </div>
      </div>
      {/* Botón para exportar a PDF */}
      <div className="text-center">
        <ExportToExcel excel={excel} />
        <button className="btn btn-danger" onClick={exportPDF}>
          Exportar a PDF
        </button>
      </div>
    </div>
  );
};

export default Report;
