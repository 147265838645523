import React, { useContext } from 'react';
import { SucursalContext } from '../../context/SucursalContext'; // Importa el contexto

const Header = () => {
  const { sucursal } = useContext(SucursalContext); 
  return (
    <header className="navbar navbar-dark bg-dark">
      <a className="navbar-brand p-4 text-center font-weight-bold" href="#">
        Mi Aplicación
      </a>
      <h4 className="navbar-brand p-4 text-center font-weight-bold" href="#">
        Sucursal: {sucursal.name}
      </h4>
    </header>
  );
};

export default Header;
