import React, { useState, useEffect } from 'react';

const StorageHistoryList = ({ selectedEmpaqueId }) => {

   
  return (
    <div className="card">
  <div className="card-header bg-info text-white">
    <h4>Historial de Movimientos</h4>
  </div>
  <div className="card-body">
    {selectedEmpaqueId.length!=0 && selectedEmpaqueId.storageHistories.length > 0 ? (
      <ul className="list-group">
        {selectedEmpaqueId.storageHistories.map((entry) => (
          <li className="list-group-item" key={entry.id}>
            {`Movido a ${entry.storageOptions?.name || 'Ubicación desconocida'} desde ${new Date(entry.startDate).toLocaleString()} hasta ${
              entry.endDate ? new Date(entry.endDate).toLocaleString() : 'Hoy'
            }`}
          </li>
        ))}
      </ul>
    ) : (
      <p>No hay historial de movimientos para este empaque.</p>
    )}
  </div>
</div>
  );
};

export default StorageHistoryList;
