import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable'

const BarcodeConfigForm = () => {
  const [barcodeConfig, setBarcodeConfig] = useState({
    batch: '',
    type: '',
    producto:'',
    cliente:'',
    clave:'',
    sucursal_id: JSON.parse(localStorage.getItem("sucursal_activa")).id,
    alumbre:'',
  });
  const [allBarcodeConfigs, setAllBarcodeConfigs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBarcodeConfig({ ...barcodeConfig, [name]: value });
  };
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedAlumbre, setSelectedAlumbre] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [typeList, setTypeList] = useState([]);  // Updated to an array
  const [cliente, setClienteList] = useState([]);  // Updated to an array
  const [alumbre, setAlumbreList] = useState([]); 

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event.selected);

  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/barcode-config/crear`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(barcodeConfig),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Configuración de código de barras creada:', data);
        setBarcodeConfig({ batch: '', type: null, producto:'', cliente:null,clave:"",alumbre:null , sucursal_id: JSON.parse(localStorage.getItem("sucursal_activa")).id});
        fetchBarcodeConfigs(); // Recargar la lista de configuraciones
        
        setShowPopup(true);
        setSelectedAlumbre(null);
        setSelectedCliente(null);
        setSelectedType(null);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        
      })
      .catch(error => console.error('Error al crear la configuración de código de barras:', error));
  };

  const fetchBarcodeConfigs = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/barcode-config/listar/${JSON.parse(localStorage.getItem("sucursal_activa")).id}`)
      .then(response => response.json())
      .then(data => setAllBarcodeConfigs(data))
      .catch(error => console.error('Error al cargar configuraciones de códigos de barras:', error));
    const filtros = await axios.get(`${process.env.REACT_APP_API_URL}/empaque/filtros`);
    rellenoVariables(filtros.data.opcionesFiltros);
  };
  const rellenoVariables = ( opcionesFiltros) => {
    const options = opcionesFiltros.reduce((acc, opcion) => {
      acc.typeList.add(opcion.barcodeConfig.type);
      acc.cliente.add(opcion.barcodeConfig.cliente);
      acc.alumbre.add(opcion.barcodeConfig.alumbre);
      return acc;
    }, {
      typeList: new Set(),
      cliente: new Set(),
      alumbre: new Set(),
    });

    // Actualizando estados
    setTypeList([...options.typeList].map(type => ({ value: type, label: type })));
    setClienteList([...options.cliente].map(cliente => ({ value: cliente, label: cliente })));
    setAlumbreList([...options.alumbre].map(alumbre => ({ value: alumbre, label: alumbre })));
   
  };
  useEffect(() => {
    fetchBarcodeConfigs();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card my-5">
            <div className="card-header bg-primary text-white">
              <h4 className="text-center">Configurar Códigos de Barras</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="batch">Lote</label>
                  <input
                    type="text"
                    className="form-control"
                    id="batch"
                    name="batch"
                    value={barcodeConfig.batch}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                <label htmlFor="type">Tipo</label>
                <CreatableSelect
                    isClearable
                    options={typeList}
                    value={typeList?typeList.find(option => option.value === barcodeConfig.type):""} // Ensure the selected value is displayed
                    // Ensure the selected value is displayed
                    onChange={(selectedOption) => {
                        setSelectedType(selectedOption);
                        setBarcodeConfig({ ...barcodeConfig, type: selectedOption?.value || '' });
                    }}
                    placeholder="Seleccione o escriba un tipo"
                />
                </div>
                <div className="form-group mb-3">
                <label htmlFor="producto">Producto</label>
                <input
                    type="text"
                    className="form-control"
                    id="producto"
                    name="producto"
                    value={barcodeConfig.producto}
                    onChange={handleChange}
                />
                </div>
                <div className="form-group mb-3">
                <label htmlFor="type">Cliente</label>
                <CreatableSelect
                    isClearable
                    options={cliente}
                    value={cliente?cliente.find(option => option.value === barcodeConfig.cliente):[]} // Ensure the selected value is displayed
                    onChange={(selectedOption) => {
                        setSelectedCliente(selectedOption);
                        setBarcodeConfig({ ...barcodeConfig, cliente: selectedOption?.value ||  [] });
                    }}
                    placeholder="Seleccione o escriba un cliente"
                />
                </div>
                <div className="form-group mb-3">
                <label htmlFor="type">Alumbre</label>
                <CreatableSelect
                    isClearable
                    options={alumbre}
                    value={alumbre?alumbre.find(option => option.value === barcodeConfig.alumbre):""} // Ensure the selected value is displayed
                    onChange={(selectedOption) => {
                        setSelectedAlumbre(selectedOption);
                        setBarcodeConfig({ ...barcodeConfig, alumbre: selectedOption?.value || '' });
                    }}
                    placeholder="Seleccione o escriba un Alumbre"
                />
                </div>
                <div className="form-group mb-3">
                <label htmlFor="clave">Clave</label>
                <input
                    type="text"
                    className="form-control"
                    id="clave"
                    name="clave"
                    value={barcodeConfig.clave}
                    onChange={handleChange}
                />
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  Crear Configuración de Código de Barras
                </button>
              </form>
              <hr />
              <h5 className="mt-4">Configuraciones Existentes</h5>
              <ul className="list-group">
              <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Codigo Barras</th>
                    <th>Lote</th>
                    <th>Clave</th>
                    <th>Cliente</th>
                    <th>Alumbre</th>
                    <th>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    {allBarcodeConfigs.map((config) => (
                       
                    <tr key={config.id}>
                        <td>{config.codigo_barras}</td>
                        <td>{config.batch}</td>
                        <td>{config.clave}</td>
                        <td>{config.cliente}</td>
                        <td>{config.alumbre}</td>
                        <td>{config.type}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
                <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Siguiente'}
              breakLabel={'...'}
              pageCount={totalPages} // Número total de páginas
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick} // Llamada a la función al cambiar de página
              containerClassName={'pagination'}
              activeClassName={'active'} // Clase activa para la página seleccionada
            />
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>Informacion Guardada correctamente</p>
        </div>
      )}
    </div>
   
  );
};

export default BarcodeConfigForm;
