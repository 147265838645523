import React, { useState } from 'react';
import UserForm from './usuario/UserForm';
import GlobalSucursalUsuario from './global/GlobalSucursalUsuario';
import GlobalSucursalUsuarioListar from './global/GlobalSucursalUsuarioListar';

const UsuarioIndex = () => {
    const [updateFlag, setUpdateFlag] = useState(false);
    const [updateListaSucursal, setUpdateListaSucursal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null); 
    const [selectedSucursal, setSelectedSucursal] = useState(null); 

    const handleUpdate = () => {
      setUpdateFlag(!updateFlag);
    };
    const updateList =()=>{
      setUpdateListaSucursal(!updateListaSucursal);
    }
    const updateUsuario = (user) => {
        setSelectedUser(user);
      };
    
  return (
    <div className="container">
      <h1>Crear Usuario</h1>
      <div className="row">
        <div className="col-md-6">

        <UserForm 
            userToEdit={selectedUser} 
            onSuccess={handleUpdate} // Limpiar el formulario después de guardar
            list={updateList} // Refrescar la lista
        />
        </div>

        <div className="col-md-6">
          <GlobalSucursalUsuario  onSuccess={updateList} key={updateListaSucursal}/>
        </div>
      </div>
        <GlobalSucursalUsuarioListar 
            sucursal={selectedSucursal}
            handleDelete={(id) => console.log(`Eliminar usuario ${id}`)} 
            flagUser={true} 
            key={updateListaSucursal}
            updateUsuario={updateUsuario} // Pasar la función para editar usuarios
            />
    </div>
  );
};

export default UsuarioIndex;
