import React, { useState, useEffect } from 'react';

const UserForm = ({ onSuccess, list, userToEdit }) => {
  // Estado inicial del formulario
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    role_id: '', // Asegúrate de que coincida con los IDs de roles disponibles
  });

  const [roles, setRoles] = useState([]);

  // Cargar roles y usuario a editar si está en modo edición
  useEffect(() => {
    // Cargar roles
    fetch(`${process.env.REACT_APP_API_URL}/role/listar`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => setRoles(data))
      .catch((error) => console.error('Error al cargar roles:', error));

    // Si hay un usuario a editar, carga sus datos en el formulario
    if (userToEdit) {
      setUser({
        name: userToEdit.name || '',
        email: userToEdit.email || '',
        password: '', // La contraseña no se pre-llena por seguridad
        role_id: userToEdit.role_id || '',
      });
    }
  }, [userToEdit]); // El useEffect se ejecuta si cambia userToEdit

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Manejar el envío del formulario
  const handleSubmit = (event) => {
    event.preventDefault();

    const url = userToEdit
      ? `${process.env.REACT_APP_API_URL}/auth/update/${userToEdit.id}` // Ruta para actualizar usuario
      : `${process.env.REACT_APP_API_URL}/auth/register`; // Ruta para crear usuario

    const method = userToEdit ? 'PUT' : 'POST'; // Si hay usuario, es edición (PUT), sino creación (POST)

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta:', data);
        list(); // Refrescar lista de usuarios
        onSuccess(); // Callback al éxito
        // Reiniciar el formulario o redirigir si es necesario
        setUser({ name: '', email: '', password: '', role_id: '' });
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card my-5">
            <div className="card-header bg-primary text-white">
              <h4 className="text-center">
                {userToEdit ? 'Editar Usuario' : 'Registrar Usuario'}
              </h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email">Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder={userToEdit ? 'Dejar en blanco para no cambiar' : 'Contraseña'}
                    required={!userToEdit} // Contraseña requerida solo si es creación
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="role_id">Rol</label>
                  <select
                    className="form-control"
                    id="role_id"
                    name="role_id"
                    value={user.role_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Selecciona un rol</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.role_name}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  {userToEdit ? 'Guardar Cambios' : 'Registrar Usuario'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
