import React, { useState, useEffect } from 'react'; 

const SucursalForm = ({ onSuccess, list, initialData }) => {
  const [sucursal, setSucursal] = useState({
    name: '',
    address: '',
    users: [],
  });
  const [allUsers, setAllUsers] = useState([]);

  // Si recibimos datos iniciales (para editar), los cargamos en el estado
  useEffect(() => {
    if (initialData) {
      setSucursal({
        name: initialData.name,
        address: initialData.address,
        users: initialData.users.map(user => user.id),
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSucursal({ ...sucursal, [name]: value });
  };

  // Cargar usuarios al montar el componente
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/listar`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.json())
    .then((data) => setAllUsers(data))
    .catch((error) => console.error('Error al cargar usuarios:', error));
  }, []);

  // Manejar el envío del formulario
  const handleSubmit = (event) => {
    event.preventDefault();

    // Definir si es creación o actualización
    const method = initialData ? 'PUT' : 'POST';
    const url = initialData
      ? `${process.env.REACT_APP_API_URL}/sucursal/update/${initialData.id}`
      : `${process.env.REACT_APP_API_URL}/sucursal/register`;

    // Enviar solicitud al servidor
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sucursal),
    })
    .then((response) => response.json())
    .then((data) => {
      onSuccess(); 
      list();
      console.log('Sucursal guardada:', data);
      setSucursal({ name: '', address: '', users: [] }); // Reiniciar el formulario
    })
    .catch((error) => console.error('Error al guardar la sucursal:', error));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="card my-5">
            <div className="card-header bg-primary text-white">
              <h4 className="text-center">{initialData ? 'Editar Sucursal' : 'Crear Sucursal'}</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Nombre de la Sucursal</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={sucursal.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="address">Dirección</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={sucursal.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="users">Asignar Usuarios</label>
                  <select
                    className="form-control"
                    id="users"
                    name="users"
                    multiple
                    value={sucursal.users}
                    onChange={(e) =>
                      setSucursal({
                        ...sucursal,
                        users: [...e.target.selectedOptions].map((opt) => opt.value),
                      })
                    }
                  >
                    {allUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name} ({user.email})
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  {initialData ? 'Actualizar Sucursal' : 'Crear Sucursal'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucursalForm;
