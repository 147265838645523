import React, { useState, useEffect, useRef } from 'react';
import '../css/IngresoEmpaque.css';

const BarcodeEntryForm = ({ userRole = "admin" }) => {
  const [formData, setFormData] = useState({
    barcode1: '',
    number: '',
    lote: '',
    type: '',
    sucursal_id:JSON.parse(localStorage.getItem("sucursal_activa")).id,
    storageOption_id: JSON.parse(localStorage.getItem("sucursal_activa")).storageOptions[0].id,
    timestamp: new Date().toISOString(),
  });
  
  const [sucursalActiva, setConfiguraciones] = useState([]);
  const inputRef = useRef(null);
  const [popupData, setPopupData] = useState({ type: '', lote: '', storageName: '' });
  const [error, setError] = useState('');
  const [existingBarcodes, setExistingBarcodes] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const storedData = localStorage.getItem('data');
    if (storedData) {
      try {
        const valueData = JSON.parse(storedData);
        let parsedData = [];
        if (valueData && valueData.value) {
          parsedData = JSON.parse(valueData.value);
        } else {
          parsedData = valueData;
        }
        if (parsedData && parsedData.sucursales && parsedData.sucursales.length > 0) {

            const sucursalesActualizadas =  JSON.parse(localStorage.getItem("sucursal_activa"));// Si el ID coincide, marcar como activa
              console.log("sucursal",sucursalesActualizadas);
            setStorageOptions(sucursalesActualizadas.storageOptions || []);
            setFormData({
            ...formData,
            storageOption_id: sucursalesActualizadas.storageOptions[0].id,
          });
        } else {
          console.error('Sucursal data is missing.');
        }
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
      }
    } else {
      console.error('No data found in localStorage.');
    }
  }, [userRole]);
  useEffect(() => {
    const { barcode1 } = formData;
    const storedConfig = localStorage.getItem(`configuracion`);
    
    if (barcode1 && barcode1.length === 9) {
      const correlativo = barcode1.slice(-3);
      const codigoSinCorrelativo = barcode1.slice(0, -3);

      const configuraciones = JSON.parse(storedConfig) || [];
      const configuracionEncontrada = configuraciones.find(
        (config) => config.codigo_barras === codigoSinCorrelativo
      );

      if (configuracionEncontrada) {
        setFormData({
          ...formData,
          number: correlativo,
          lote: configuracionEncontrada.batch,
          type: configuracionEncontrada.type,
        });
      }else{
        setError('Actualizando Configuraciones, intentelo de nuevo');
        setFormData({
            barcode1: '',
            number: '',
            lote: formData.lote,
            type: formData.type,
            sucursal_id: formData.sucursal_id,
            storageOption_id: formData.storageOption_id,
            timestamp: new Date().toISOString(),
          });
          inputRef.current.focus();
        obtenerConfiguraciones();
        
      }
    }
  }, [formData.barcode1, existingBarcodes]);
  useEffect(() => {
    const { number, lote, type } = formData;
    if (number && lote && type) {
        console.log(formData);
        handleSubmit(); // Llama a handleSubmit si todos los campos están llenos
    }
}, [formData.number, formData.lote, formData.type]);
  const obtenerConfiguraciones = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/barcode-config/listar/${JSON.parse(localStorage.getItem("sucursal_activa")).id}`);
      const data = await response.json();
      setConfiguraciones(data);
      localStorage.setItem(`configuracion`, JSON.stringify(data));
      console.log('Configuraciones obtenidas de la API:', data);
    } catch (error) {
      console.error('Error al obtener configuraciones:', error);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    obtenerConfiguraciones();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault(); // Asegúrate de prevenir el comportamiento por defecto

    const { barcode1 } = formData;

    if (existingBarcodes.includes(barcode1)) {
      setError('El número de código de barras ya existe.');
      setFormData({
        barcode1: '',
        number: '',
        lote: '',
        type: '',
        sucursal_id: formData.sucursal_id,
        storageOption_id: formData.storageOption_id,
        timestamp: new Date().toISOString(),
      });
      inputRef.current.focus();
      return;
    }

    setError('');
    console.log(formData);
    fetch(`${process.env.REACT_APP_API_URL}/empaque/crear`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        startDate: new Date().toISOString(),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Data submitted successfully:', data);

        if (data.message === "El número ya ha sido utilizado.") {
          setError('El número ya ha sido utilizado.');
          setFormData({
            barcode1: '',
            number: '',
            lote: '',
            type: '',
            sucursal_id: formData.sucursal_id,
            storageOption_id: formData.storageOption_id,
            timestamp: new Date().toISOString(),
          });
          inputRef.current.focus();
        } else {
        console.log(formData);
          setPopupData({
            type: formData.type,
            lote: formData.lote,
            storageName: storageOptions.find(option => option.id === formData.storageOption_id)?.name || 'No definido',
          });

          setShowPopup(true);
          setError('');

          setTimeout(() => {
            setShowPopup(false);
          }, 3000);

          setFormData({
            barcode1: '',
            number: '',
            lote: formData.lote,
            type: formData.type,
            sucursal_id: formData.sucursal_id,
            storageOption_id: formData.storageOption_id,
            timestamp: new Date().toISOString(),
          });
          inputRef.current.focus();
        }
      })
      .catch((error) => console.error('Error submitting data:', error));
  };

  return (
    <div className="container">
      <h2>Ingreso de Códigos de Barras</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="storageOption_id">Opción de Almacenamiento</label>
          <select
            className="form-control"
            id="storageOption_id"
            name="storageOption_id"
            value={formData.storageOption_id}
            onChange={handleChange}
            required
          >
            {storageOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="barcode1">Código de Barras 1</label>
          <input
            type="text"
            className="form-control"
            id="barcode1"
            name="barcode1"
            value={formData.barcode1}
            onChange={handleChange}
            ref={inputRef}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="lote">Lote</label>
          <input
            type="text"
            className="form-control"
            id="lote"
            name="lote"
            value={formData.lote}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="type">Tipo</label>
          <input
            type="text"
            className="form-control"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
          />
        </div>

        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </form>

      {showPopup && (
        <div className="popup">
          <p>Tipo: {popupData.type || 'No definido'}</p>
          <p>Lote: {popupData.lote || 'No definido'}</p>
          <p>Hora: {new Date().toLocaleTimeString()}</p>
          <p>Almacenado en: {popupData.storageName}</p>
        </div>
      )}
    </div>
  );
};

export default BarcodeEntryForm;
