import React, { useContext, useState, useEffect } from 'react';
import { SucursalContext } from '../../context/SucursalContext'; // Importa el contexto
import { Table } from 'react-bootstrap';

const SelectSucursal = () => {
  const { updateSucursal } = useContext(SucursalContext); // Usa el contexto para actualizar la sucursal
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('data');
    if (storedData) {
      try {
        const valueData = JSON.parse(storedData);
        let parsedData = [];
        if (valueData && valueData.value) {
          parsedData = JSON.parse(valueData.value);
        } else {
          parsedData = valueData;
        }
        if (parsedData && parsedData.sucursales && parsedData.sucursales.length > 0) {
          setSucursales(parsedData.sucursales);
        } else {
          console.error('Sucursal data is missing.', console.log(parsedData));
          console.log( localStorage.getItem('data'));
        }
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
      }
    } else {
      console.error('No data found in localStorage.');
    }
  }, []);

  const seleccionar = (id) => {
    console.log(id);
    setLoading(true); 
    const selectedSucursal = sucursales[id];
    console.log(selectedSucursal);
    updateSucursal(selectedSucursal); // Actualiza el contexto y localStorage
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="row mb-5">
      <div className="col">
        <h5>Detalle de Empaques</h5>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {sucursales.map((opcion, index) => (
                <tr key={index + '-filter'}>
                  <td>{opcion.name}</td>
                  <td>{opcion.address}</td>
                  <td>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => seleccionar(index)}
                      disabled={loading}
                    >
                      {loading ? 'Cargando Configuraciones...' : 'Seleccionar'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SelectSucursal;
