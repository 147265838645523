import React, { useState, useContext } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { setWithExpiry } from '../utils/storage'
import { SucursalContext } from '../context/SucursalContext'; // Importa el contexto


const Login = () => {
    const { updateSucursal } = useContext(SucursalContext); // Usa el contexto para actualizar la sucursal
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado para manejar la carga
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); // Mostrar indicador de carga

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password });
        console.log(res);
        const { token, user,rol, role_id, sucursales } = res.data;
        if (role_id == 2) {
            localStorage.setItem('token', token);
            localStorage.setItem('data', JSON.stringify({"user":user,sucursales,role_id,rol}));
            localStorage.setItem('sucursal_activa', JSON.stringify(sucursales[0]));
            updateSucursal(sucursales[0]);
            console.log(JSON.stringify(sucursales[0]));
            navigate(`${process.env.REACT_APP_URI_PROD}empaque`);
        } else {
            const oneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
            setWithExpiry('token', token, oneDay);
            console.log(JSON.stringify({"user":user,sucursales,role_id,rol}));
            setWithExpiry('data', JSON.stringify({"user":user,sucursales,role_id,rol}), oneDay);
            navigate(`${process.env.REACT_APP_URI_PROD}select-sucursal`);
        }
    } catch (err) {
      setError('Login failed. Please check your credentials.');
    } finally {
      setLoading(false); // Ocultar indicador de carga
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-12">
          <h2 className="text-center mb-4">Login</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className='form-control'
                required
                autoFocus
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className='form-control'
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
