import React, { useState } from 'react';
import SucursalForm from './sucursal/SucursalForm';
import GlobalSucursalUsuario from './global/GlobalSucursalUsuario';
import GlobalSucursalUsuarioListar from './global/GlobalSucursalUsuarioListar';

const SucursalIndex = () => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateListaUsuario, setUpdateListaUsuario] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState(null); // Para editar

  const handleUpdate = () => {
    setUpdateFlag(!updateFlag);
  };

  const updateList = () => {
    setUpdateListaUsuario(!updateListaUsuario);
  };


  const updateSucursal = (sucursalUpdate) => {
    setSelectedSucursal(sucursalUpdate);
   };
  return (
    <div className="container">
      <h1>Opciones de Configuración</h1>
      <div className="row">
        <div className="col-md-6">
          <SucursalForm 
            onSuccess={handleUpdate} 
            list={updateList} 
            initialData={selectedSucursal} // Pasar datos si se está editando
          />
        </div>
        <div className="col-md-6">
          <GlobalSucursalUsuario onSuccess={handleUpdate} key={updateListaUsuario} />
        </div>
      </div>
      <div className="col-md-12">
        <GlobalSucursalUsuarioListar key={updateFlag} flagSucursal={true}  updateSucursal={updateSucursal}  />
      </div>
    </div>
  );
};

export default SucursalIndex;
