import React from 'react';
import Header from '../components/layout/Header.js';
import Layout from '../components/layout/Layout.js';

const MainLayout = ({ children }) => {
  return (
    <div className="d-flex" id="wrapper" style={{ minHeight: '100vh' }}>
      {/* Sidebar */}
      <Layout />

      {/* Main content area */}
      <div id="page-content-wrapper" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Header />
        <div className="container-fluid" style={{ flexGrow: 1 }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
