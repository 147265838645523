import React from 'react';
import { Button } from 'react-bootstrap';

const GlobalSucursalList = ({ sucursal, handleDelete, flagUser, flagSucursal, updateSucursal, updateUsuario }) => {
  const users = sucursal?.users || [];
  return (
    <>
      <tbody>
        {users.length > 0 ? (
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{sucursal.name}</td>
              <td>
                <Button
                  onClick={() => handleDelete(user.id)}
                  variant="danger"
                  className="mr-2"
                >
                  Eliminar Relación
                </Button>
                {/* Mostrar botón de Editar Usuario si flagUser está presente */}
                {flagUser && (
                  <Button
                    onClick={() => updateUsuario(user)} // Pasar el usuario completo para editar
                    variant="primary"
                    className="mr-2"
                  >
                    Editar Usuario
                  </Button>
                )}
                {/* Mostrar botón de Editar Sucursal si flagSucursal está presente */}
                {flagSucursal && (
                  <Button
                    onClick={() => updateSucursal(sucursal)}
                    variant="secondary"
                  >
                    Editar Sucursal
                  </Button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">No hay usuarios asignados.</td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export default GlobalSucursalList;
