import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import GlobalSucursalList from './GlobalSucursalList';

const GlobalSucursalUsuarioListar = ({flagUser,flagSucursal,updateUsuario,updateSucursal, list}) => {
  const [sucursales, setSucursal] = useState([]); // Cambiado de null a []
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchSucursal();
  }, []);
  const fetchSucursal = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sucursal/listarSucursalUsuario`);
      const data = await response.json();
      setSucursal(data);
    } catch (err) {
      setError('Error al cargar las sucursales.');
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (userId, sucursalId) => {
    setError(null);
    setSuccessMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/eliminar-relacion`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          sucursal_id: sucursalId,
        }),
      });

      if (!response.ok) {
        throw new Error('Error al eliminar la relación.');
      }

      setSuccessMessage('Relación eliminada con éxito.');
      fetchSucursal();
      // Aquí puedes agregar lógica para actualizar el listado de usuarios
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="container mt-4">
      <h2>Sucursales y Usuarios Asignados</h2>
      <div>
      <table className="table table-striped">
        <thead>
            <tr>
            <th>Usuario</th>
            <th>Correo</th>
            <th>Sucursal</th>
            <th>Acciones</th>
            </tr>
        </thead>
        {sucursales.length > 0 ? (
          sucursales.map((sucursal) => (
            <GlobalSucursalList
              key={sucursal.id}
              sucursal={sucursal}
              flagUser={flagUser}
              updateUsuario={updateUsuario}
              updateSucursal={updateSucursal}
              flagSucursal={flagSucursal}
              list={list}
              handleDelete={(userId) => handleDelete(userId, sucursal.id)}
            />
          ))
        ) : (
          <p>No hay sucursales disponibles.</p>
        )}
         </table>
      </div>
    </div>
  );
};

export default GlobalSucursalUsuarioListar;
