import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';

const AssignUserToBranch = ({onSuccess}) => {
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Obtener sucursales desde la API
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sucursal/listarSucursal`);
        const data = await response.json();
        setBranches(data);
      } catch (err) {
        setError('Error al cargar las sucursales.');
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/listar`);
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        setError('Error al cargar los usuarios.');
      }
    };

    fetchBranches();
    fetchUsers();
  }, []);

  // Manejar la asignación del usuario
  const handleAssign = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/asignar-usuario`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: selectedUser,
          branchId: selectedBranch,
        }),
      });

      if (!response.ok) {
        throw new Error('Error al asignar usuario a la sucursal.');
      }

      onSuccess(); // Llamar al callback cuando se asigna un usuario
      setSuccessMessage('Usuario asignado con éxito.');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="card my-5">
            <div className="card-header bg-primary text-white">
              <h4 className="text-center">Asignar Sucursal</h4>
                {error && <Alert variant="danger">{error}</Alert>}
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
            </div>
            <div  className="card-body">
                <Form onSubmit={handleAssign}>
                    <Form.Group controlId="branchSelect">
                    <Form.Label>Sucursal</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedBranch}
                        onChange={(e) => setSelectedBranch(e.target.value)}
                        required
                    >
                        <option value="">Selecciona una sucursal</option>
                        {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                            {branch.name}
                        </option>
                        ))}
                    </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="userSelect" className="mt-3">
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        required
                    >
                        <option value="">Selecciona un usuario</option>
                        {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.name}
                        </option>
                        ))}
                    </Form.Control>
                    </Form.Group>

                    <Button className="mt-4" type="submit" variant="primary">
                    Asignar Usuario
                    </Button>
                </Form>
                </div>
            </div>
        </div>
    </div>
</div>

  );
};

export default AssignUserToBranch;
