import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

const BarcodeConfigForm = () => {
 
  const [allBarcodeConfigs, setAllBarcodeConfigs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [showPopup, setShowPopup] = useState(false);
  const fetchBarcodeConfigs = () => {
    fetch(`${process.env.REACT_APP_API_URL}/barcode-config/listar/${JSON.parse(localStorage.getItem("sucursal_activa")).id}`)
      .then(response => response.json())
      .then(data => setAllBarcodeConfigs(data))
      .catch(error => console.error('Error al cargar configuraciones de códigos de barras:', error));
      
  };
  const handleDeleteCode = async (id) => {
      try {
        // Enviamos el motivo como parámetro de consulta
        await axios.delete(`${process.env.REACT_APP_API_URL}/barcode-config/eliminar/${id}`);
        fetchBarcodeConfigs(); // Volver a cargar los datos después de eliminar
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } catch (error) {
        console.error('Error al eliminar el empaque', error);
      }
  };
  useEffect(() => {
    fetchBarcodeConfigs();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event.selected);
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <h5 className="mt-4">Listado de Codigos</h5>
        <ul className="list-group">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Codigo Barras</th>
                        <th>Lote</th>
                        <th>Clave</th>
                        <th>Cliente</th>
                        <th>Alumbre</th>
                        <th>Tipo</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {allBarcodeConfigs.map((config) => (
                        
                    <tr key={config.id}>
                        <td>{config.codigo_barras}</td>
                        <td>{config.batch}</td>
                        <td>{config.clave}</td>
                        <td>{config.cliente}</td>
                        <td>{config.alumbre}</td>
                        <td>{config.type}</td>
                        <td><button
                            className="btn btn-danger"
                            onClick={() => handleDeleteCode(config.id)}
                            >
                            Eliminar
                        </button> </td>
                    </tr>
                    ))}
                </tbody>
                
            </table>
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Siguiente'}
              breakLabel={'...'}
              pageCount={totalPages} // Número total de páginas
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick} // Llamada a la función al cambiar de página
              containerClassName={'pagination'}
              activeClassName={'active'} // Clase activa para la página seleccionada
            />
        </ul>
      </div>
      {showPopup && (
        <div className="popup">
          
          <p>Informacion eliminada correctamente</p>
        </div>
      )}
    </div>
  );
};

export default BarcodeConfigForm;
