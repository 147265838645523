import React, { useState, useEffect } from 'react';

const StorageOptionForm = (storageEdit) => {
  // Estado para almacenar la opción de guardado
  const [storageOption, setStorageOption] = useState({
    location: '',
    name: '',
    maxQuantity: '',
    sucursalId: JSON.parse(localStorage.getItem("sucursal_activa")).id,
  });
  const [allSucursales, setAllSucursales] = useState([]);
  const [allOpciones, setAllOpciones] = useState([]);

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(storageOption);
    setStorageOption({ ...storageOption, [name]: value });
  };

  // Cargar sucursales al montar el componente
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/sucursal/listarSucursal`) // Ajusta la URL de tu API
      .then(response => response.json())
      .then(data => setAllSucursales(data))
      .catch(error => console.error('Error al cargar sucursales:', error));

       // Si hay un usuario a editar, carga sus datos en el formulario
    if (storageEdit) {
        setStorageOption({
          location: storageEdit.location ||'',
          name:  storageEdit.name ||'',
          maxQuantity:  storageEdit.maxQuantity ||'',
          sucursalId:  storageEdit.sucursalId ||'',
        });
      }
  }, []);

  // Manejar el envío del formulario
  const handleSubmit = (event) => {
    event.preventDefault();
  
    const url = storageEdit
    ? `${process.env.REACT_APP_API_URL}/storage-option/update/${storageEdit.id}` // Ruta para actualizar usuario
    : `${process.env.REACT_APP_API_URL}/storage-option/crear`; // Ruta para crear usuario

   const method = storageEdit ? 'PUT' : 'POST'; // Si hay usuario, es edición (PUT), sino creación (POST)
    fetch(`${process.env.REACT_APP_API_URL}/storage-option/crear`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(storageOption),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Opción de guardado creada:', data);
       
        // Reiniciar el formulario o redirigir a otra página si es necesario
        setStorageOption({ location: '', name: '', maxQuantity: '', sucursalId: '' , });
        fetchOpcionesGuardado();
      })
      .catch(error => console.error('Error al crear la opción de guardado:', error));
  };
  const fetchOpcionesGuardado = () => {
    fetch(`${process.env.REACT_APP_API_URL}/storage-option/listar/${JSON.parse(localStorage.getItem("sucursal_activa")).id}`)
      .then(response => response.json())
      .then(data => setAllOpciones(data))
      .catch(error => console.error('Error al cargar configuraciones de códigos de barras:', error));
      console.log(allOpciones)
  };
  
  useEffect(() => {
    fetchOpcionesGuardado();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card my-5">
            <div className="card-header bg-primary text-white">
              <h4 className="text-center">Registrar Opción de Guardado</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="location">Ubicación</label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={storageOption.location}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={storageOption.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="maxQuantity">Cantidad Máxima de Existencias</label>
                  <input
                    type="number"
                    className="form-control"
                    id="maxQuantity"
                    name="maxQuantity"
                    value={storageOption.maxQuantity}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="sucursalId">Sucursal</label>
                  <select
                    id="sucursalId"
                    name="sucursalId"
                    className="form-control"
                    value={storageOption.sucursalId}
                    onChange={handleChange}
                    required
                  >
                    <option value="0">Seleccione una sucursal</option>
                    {allSucursales.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  Registrar Opción de Guardado
                </button>
              </form>
              <hr />
              <h5 className="mt-4">Configuraciones Existentes</h5>
              <ul className="list-group">
              <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Ubicación</th>
                    <th>Sucursal</th>
                    </tr>
                </thead>
                <tbody>
                    {allOpciones.map((config) => (
                       
                    <tr key={config.id}>
                        <td>{config.name}</td>
                        <td>{config.location}</td>
                        <td>{config.sucursals ? config.sucursals.name : 'Sin Sucursal'}</td>
                    </tr>
                    ))}
                </tbody>
                </table>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageOptionForm;
