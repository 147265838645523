import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ excel }) => {
  const exportToExcel = () => {
    console.log(excel);
    const info = excel.map(celda => ({
        'Fecha y Hora': new Date(celda.horaGuardado).toLocaleString(),
        Nombre: celda.nombre,
        Lote: celda.barcodeConfig.batch,
        Tipo: celda.barcodeConfig.type,
        Número: celda.number,
        Clave: celda.barcodeConfig.clave,
        Cliente: celda.barcodeConfig.cliente,
        Producto: celda.barcodeConfig.producto,
        Alumbre: celda.barcodeConfig.alumbre
      }));
  
    // Crea un libro de trabajo y una hoja de trabajo
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(info);

    // Agrega la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

    // Genera un archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Crea un nuevo blob para la descarga
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    // Guarda el archivo
    saveAs(data, 'datos.xlsx');
  };

  return (
    <button onClick={exportToExcel} className="btn btn-primary" style={{ margin: '10px', padding: '10px' }}>
      Exportar a Excel
    </button>
  );
};

export default ExportToExcel;
