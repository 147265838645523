// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { getWithExpiry } from '../utils/storage';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  
  
  useEffect(() => {
    const checkExpiry = () => {
      const storedToken = localStorage.getItem('token');
      const storedUser = localStorage.getItem('data');
  
      if (storedToken && storedUser) {
        setToken(storedToken);
        setUser(JSON.parse(storedUser));
      } else {
        const expToken = getWithExpiry('token');
        const expUser = getWithExpiry('data');
  
        if (expToken && expUser) {
          setToken(expToken);
          setUser(expUser);
        } else {
          logout(); // Eliminar sesión si ha expirado
        }
      }
    };
  
    checkExpiry();
  
    // Opcional: puedes establecer un intervalo para verificar la expiración periódicamente
    const interval = setInterval(checkExpiry, 60 * 1000); // Cada minuto
  
    return () => clearInterval(interval);
  }, []);
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('data');
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, token, setUser, setToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
