import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import SucursalIndex from './components/SucursalIndex';
import UserForm from './components/UserIndex';
import OpcionesPage from './components/OpcionesComponnents';
import Empaque from './components/IngresoEmpaque';
import Informe from './components/Informe';
import StorageHistory from './components/StorageHistory';  // Importa tu layout principal
import SelectSucursal from './components/sucursal/SelectSucursal';  // Importa tu layout principal
import MainLayout from './layouts/MainLayout';  // Importa tu layout principal
import Eliminacion from './components/Eliminacion';
const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas que no usan el layout principal, como la página de Login */}
        <Route path="/" element={<Login />} />
        <Route path="/registro" element={
          <MainLayout>
            <UserForm />
          </MainLayout>
        } /> 

        {/* Rutas que usan el layout principal */}
        <Route path="/sucursal" element={
          <MainLayout>
            <SucursalIndex />
          </MainLayout>
        } />
        <Route path="/opciones" element={
          <MainLayout>
            <OpcionesPage />
          </MainLayout>
        } />
         <Route path="/cambio" element={
          <MainLayout>
            <StorageHistory />
          </MainLayout>
        } />
        <Route path="/empaque" element={
          <MainLayout>
            <Empaque />
          </MainLayout>
        } />
        <Route path="/informe" element={
          <MainLayout>
            <Informe />
          </MainLayout>
        } />
        <Route path="/select-sucursal" element={
          <MainLayout>
            <SelectSucursal />
          </MainLayout>
            } />
            <Route path="/eliminacion" element={
            <MainLayout>
                <Eliminacion />
            </MainLayout>
        } />
      
      </Routes>
    </Router>
  );
};

export default App;
