import React, { createContext, useState, useEffect } from 'react';

// Crear el contexto
export const SucursalContext = createContext();

// Crear un proveedor para envolver la aplicación
export const SucursalProvider = ({ children }) => {
  const [sucursal, setSucursal] = useState({ name: 'no Seleccionado' });

  // Actualizar la sucursal desde el localStorage si hay cambios
  useEffect(() => {
    const storedSucursal = localStorage.getItem('sucursal_activa');
    if (storedSucursal) {
      setSucursal(JSON.parse(storedSucursal));
    }
  }, []);

  const updateSucursal = (newSucursal) => {
    localStorage.setItem('sucursal_activa', JSON.stringify(newSucursal));
    setSucursal(newSucursal); // Actualizamos el contexto
  };

  return (
    <SucursalContext.Provider value={{ sucursal, updateSucursal }}>
      {children}
    </SucursalContext.Provider>
  );
};
