import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert, Form , Button} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import '../../css/Paginacion.css';

const EliminarEmpaque = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());
  // Estados para filtros
  const [formData, setFormData] = useState({
    type:"",
    batch:"",
    sucursal_id:JSON.parse(localStorage.getItem("sucursal_activa")).id, 
    cliente:"",
    alumbre:"",
    producto:"",
    clave:"",
    storageOption_id:"",
    estado:"",
    fecha:"",
    fechaHasta:""
  });
  const [opciones, setOpciones] = useState('');
  const [storageOptionsList, setStorageOptionsList] = useState('');
  const [typeList, setTypeList] = useState('');
  const [loteList, setLoteList] = useState('');
  const [clave, setClaveList] = useState('');
  const [cliente, setClienteList] = useState('');
  const [alumbre, setAlumbreList] = useState('');
  const [producto, setProductoList] = useState('');
  const itemsPerPage = 10;
 
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/empaque/informe`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          formData
        },
      });
      const filtros = await axios.get(`${process.env.REACT_APP_API_URL}/empaque/filtros`);
      console.log(response.data);
      setTotalPages(response.data.totalPages);
      setReportData(response.data);
      rellenoVariables(response.data,filtros.data.opcionesFiltros);
      setLoading(false);

    } catch (error) {
      console.error('Error al obtener los datos', error);
    }
    setLoading(false);
  };

  const handleRowSelect = (id) => {
    setSelectedRows(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(id)) {
        newSelection.delete(id); // Deselect if already selected
      } else {
        newSelection.add(id); // Select if not selected
      }
      return newSelection;
    });
  };

  const handleBulkDelete = async () => {
    const motivo = prompt("Por favor ingrese el motivo de la eliminación:");
    if (motivo && window.confirm("¿Está seguro de que desea eliminar los elementos seleccionados?")) {
      const idsToDelete = Array.from(selectedRows);
      
      try {
        // Enviamos el motivo y los IDs en el cuerpo de la solicitud
        await axios.post(`${process.env.REACT_APP_API_URL}/empaque/eliminar`, {
          ids: idsToDelete,
          motivo: motivo
        });
  
        // Volver a cargar los datos después de eliminar
        fetchData();
        setShowPopup(true);
  
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
  
        console.log("Eliminar IDs:", idsToDelete);
        
      } catch (error) {
        console.error('Error al eliminar los empaques', error);
      }
  
      // Después de la eliminación, limpiar las filas seleccionadas
      setSelectedRows(new Set());
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage,formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleDelete = async (id) => {
    const motivo = prompt('¿Cuál es el motivo de la eliminación?'); // Pedir al usuario el motivo
    if (motivo && window.confirm('¿Estás seguro de que deseas eliminar este empaque?')) {
      try {
        // Enviamos el motivo como parámetro de consulta
        await axios.post(`${process.env.REACT_APP_API_URL}/empaque/eliminar`, {
            ids: [id],
            motivo: motivo
          });
    
        fetchData(); // Volver a cargar los datos después de eliminar
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } catch (error) {
        console.error('Error al eliminar el empaque', error);
      }
    }
  };
 
  const rellenoVariables = (data, opcionesFiltros) => {
    setOpciones(data.opciones);
    const options = opcionesFiltros.reduce((acc, opcion) => {
      acc.storageOptionsList.add(opcion.nombre);
      acc.typeList.add(opcion.barcodeConfig.type);
      acc.loteList.add(opcion.barcodeConfig.batch);
      acc.clave.add(opcion.barcodeConfig.clave);
      acc.cliente.add(opcion.barcodeConfig.cliente);
      acc.alumbre.add(opcion.barcodeConfig.alumbre);
      acc.producto.add(opcion.barcodeConfig.producto);
      return acc;
    }, {
      storageOptionsList: new Set(),
      typeList: new Set(),
      loteList: new Set(),
      clave: new Set(),
      cliente: new Set(),
      alumbre: new Set(),
      producto: new Set(),
    });
    // Actualizando estados
    setTypeList([...options.typeList]);
    setStorageOptionsList([...options.storageOptionsList]);
    setLoteList([...options.loteList]);
    setClaveList([...options.clave]);
    setClienteList([...options.cliente]);
    setAlumbreList([...options.alumbre]);
    setProductoList([...options.producto]);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!reportData) {
    return (
      <Alert variant="danger">
        Hubo un error al cargar los datos.
      </Alert>
    );
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event.selected);

  };

  return (
    <div>
      <h2>Lista de Empaques</h2>
       {/* Filtros */}
       <div className="row mb-4">
        <div className="col-md-4">
          <Form.Group >
            <Form.Label>Almacenamiento</Form.Label>
            <Form.Control
              as="select"
              id="storageOption_id"
              name="storageOption_id"
              onChange={handleChange}
              value={formData.storageOption_id} 
            >
              <option value="">Todas</option>
              {storageOptionsList.map((option, index) => (
                <option key={index+"-StorageOption"} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              name="type"
              id="type"
              value={formData.type} 
              onChange={handleChange}
            >
              <option value="">Todos</option>
              {typeList.map((type, index) => (
                <option key={index+"-type"} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group >
            <Form.Label> Lote</Form.Label>
            <Form.Control
              as="select"
              name="batch"
              id="batch"
              value={formData.batch} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {loteList.map((lote, index) => (
                <option key={index+"-lote"} value={lote}>
                  {lote}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group >
            <Form.Label> Producto</Form.Label>
            <Form.Control
              as="select"
              name="producto"
              id="producto"
              value={formData.producto} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {producto.map((producto, index) => (
                <option key={producto+"-lote"} value={producto}>
                  {producto}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Clave</Form.Label>
            <Form.Control
              as="select"
              id="clave"
              name="clave"
              value={formData.clave} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {clave.map((lote, index) => (
                <option key={index+"-lote"} value={lote}>
                  {lote}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label> Cliente</Form.Label>
            <Form.Control
              as="select"
              onChange={handleChange}
              name="cliente"
              value={formData.cliente} 

              id="cliente"
            >
              <option value="">Todos</option>
              {cliente.map((clave, index) => (
                <option key={index+"-clave"} value={clave}>
                  {clave}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group >
            <Form.Label> Alumbre</Form.Label>
            <Form.Control
              as="select"
              name="alumbre"
              id="alumbre"
              value={formData.alumbre} 

              onChange={handleChange}
            >
              <option value="">Todos</option>
              {alumbre.map((alumbre, index) => (
                <option key={index+"-alumbre"} value={alumbre}>
                  {alumbre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group >
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              id="fecha"
              value={formData.fecha} 

              onChange={handleChange}
            />
          </Form.Group>
        </div>
        <div className="col-md-4">
            <Form.Group >
                <Form.Label>Estado Empaque</Form.Label>
                <Form.Control
                as="select"
                name="estado"
                id="estado"
                value={formData.producto} 

                onChange={handleChange}
                >
                <option value="">Todos</option>
                {/* Asegúrate de cambiar estos valores por los valores reales de estado */}
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
                </Form.Control>
            </Form.Group>
            </div>
      </div>
      {loading ? (
        <p>Cargando datos...</p>
      ) : (
        

        <div className="table-responsive">
            <Button
                variant="danger"
                onClick={handleBulkDelete}
                disabled={selectedRows.size === 0} // Desactiva el botón si no hay elementos seleccionados
            >
                Eliminar Seleccionados
        </Button>
            <Table striped bordered hover>
              <thead>
                <tr>
                <th>
                    <Form.Check
                        type="checkbox"
                    />
                    </th>
                    <th>Fecha y Hora</th>
                    <th>Nombre</th>
                    <th>Lote</th>
                    <th>Tipo</th>
                    <th>Número</th>
                    <th>Clave</th>
                    <th>Cliente</th>
                    <th>Producto</th>
                    <th>Alumbre</th>
                    <th>Estado Empaque</th>
                    <th>Eliminar</th>
                    
                    </tr>
              </thead>
              <tbody>
                {opciones.map((opcion, index) => (
                  <tr key={index+"-filter"}>
                       <td >{opcion.estadoEmpaque!==0?(
                        <Form.Check
                        type="checkbox"
                        checked={selectedRows.has(opcion.id)}
                        onChange={() => handleRowSelect(opcion.id)}
                        />
                    ):""} </td>
                    <td>{new Date(opcion.horaGuardado).toLocaleString()}</td>
                    <td>{opcion.nombre}</td>
                    <td>{opcion.barcodeConfig.batch}</td>
                    <td>{opcion.barcodeConfig.type}</td>
                    <td>{opcion.number}</td>
                    <td>{opcion.barcodeConfig.clave}</td>
                    <td>{opcion.barcodeConfig.cliente}</td>
                    <td>{opcion.barcodeConfig.producto}</td>
                    <td>{opcion.barcodeConfig.alumbre}</td>
                    <td>{opcion.estadoEmpaque===0?"eliminado":"activo"}</td>
                    <td>
                        {opcion.estadoEmpaque===0?(
                            <button
                            className="btn btn-danger disabled "
                            >
                            Eliminar
                            </button>
                            
                        ):<button
                        className="btn btn-danger"
                        onClick={() => handleDelete(opcion.id)}
                        >
                        Eliminar
                        </button> }
                 
                </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Siguiente'}
              breakLabel={'...'}
              pageCount={totalPages} // Número total de páginas
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick} // Llamada a la función al cambiar de página
              containerClassName={'pagination'}
              activeClassName={'active'} // Clase activa para la página seleccionada
            />
          </div>
      )}
       {showPopup && (
        <div className="popup">
          <p>Informacion eliminada correctamente</p>
        </div>
      )}
    </div>
    
  );
};

export default EliminarEmpaque;
