import React from 'react';
import Sidebar from './Sidebar'; // Asegúrate de que Sidebar esté correctamente importado

const Layout = ({ children }) => {
  return (
    <div className="d-flex" style={{ minHeight: '100vh'  }}>
      <Sidebar />
        {children}
    </div>
  );
};

export default Layout;
