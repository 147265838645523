import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [role, setRole] = useState('');

  useEffect(() => {
    const data = localStorage.getItem("data");

    if (data) {
        try {
            const parsedData = JSON.parse(data);
            if(parsedData && parsedData.value){
                setRole(JSON.parse(parsedData?.value)?.rol) 
            }else{
                setRole(parsedData?.rol);
            }
           
        } catch (error) {
            console.error("Error parsing localStorage data:", error);
        }
    }
}, []); 
  
  return (
    <div className="bg-dark text-white border-right" id="sidebar-wrapper" style={{ minHeight: '100vh', width: '250px' }}>
      <div className="sidebar-heading p-4 text-center font-weight-bold">Menú</div>
      <div className="list-group list-group-flush">
      <Link to="/" className="list-group-item list-group-item-action bg-dark text-white">
              Inicio
            </Link>
            {/* Opciones comunes a todos los roles */}
        <Link to="/empaque" className="list-group-item list-group-item-action bg-dark text-white">
          Empaque
        </Link>
        <Link to="/cambio" className="list-group-item list-group-item-action bg-dark text-white">
          Cambio Ubicacion
        </Link>
     
        {/* Opciones visibles para todos */}
        {role === 'admin' && (
          <>
          
            <Link to="/opciones" className="list-group-item list-group-item-action bg-dark text-white">
              Configuraciones
            </Link>
            <Link to="/sucursal" className="list-group-item list-group-item-action bg-dark text-white">
              Sucursales
            </Link>
            <Link to="/registro" className="list-group-item list-group-item-action bg-dark text-white">
              Registro Usuario
            </Link>
            <Link to="/select-sucursal" className="list-group-item list-group-item-action bg-dark text-white">
              Seleccionar Sucursal
            </Link>
            <Link to="/eliminacion" className="list-group-item list-group-item-action bg-dark text-white">
              Eliminar Informacion
            </Link>
          </>
        )}

        {role === 'supervisor' && (
          <>
           
           <Link to="/opciones" className="list-group-item list-group-item-action bg-dark text-white">
              Configuraciones
            </Link>
            <Link to="/registro" className="list-group-item list-group-item-action bg-dark text-white">
              Registro Usuario
            </Link>
            <Link to="/eliminacion" className="list-group-item list-group-item-action bg-dark text-white">
              Eliminar Informacion
            </Link>
          </>
        )}

        <Link to="/informe" className="list-group-item list-group-item-action bg-dark text-white">
          Informes
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
